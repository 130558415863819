import * as React from 'react';
import Title from '../misc/Title';

interface PrivacyState {
  showEmails: boolean;
}

export default class Privacy extends React.Component<{}, PrivacyState> {
  constructor(props: void) {
    super();
    this.state = {
      showEmails: false
    };
  }
  componentDidMount() {
    this.setState({
      showEmails: true
    });
  }
  render() {
    const contactEmail = this.state.showEmails ? 'contact@scribay.com' : '';
    return (
      <div className='ui grid' style={{ backgroundColor: '#fff'}}>
        <div className="ui text read container" style={{ paddingTop: '30px', paddingBottom: '80px' }}>
          <Title title="Politique de protection des données personnelles" />
          <h1>Politique de protection des données personnelles <small>(dernière mise à jour : le 02 juin 2022)</small></h1>
          <div className="ui basic segment">
            <h2>Introduction</h2>
            <p>La présente politique de confidentialité a pour objet de définir les modalités suivant lesquelles l’Atelier des auteurs (société du groupe Editis, ci-après « nous » ou « l’Atelier des auteurs »), Société par actions simplifiée au capital de 5000 euros, 820 829 356 RCS Paris, ayant son siège social au 92, avenue de France, 75013 Paris, traite, en qualité de responsable de traitement, les données recueillies dans le cadre de l’utilisation du site www.atelierdesauteurs.com (ci-après dénommé « le Site »), et de l’adhésion aux services payants et/ou gratuits du Site (ci-après « les Services ») par les utilisateurs (« vous »  ou « les Utilisateurs » ).</p>
            <p>Nous nous engageons à respecter les dispositions de la loi Informatique et Libertés du 6 janvier 1978 modifiée et celles du Règlement (UE) 2016/679 relatif à la protection des données à caractère personnel. A ce titre, nous veillons à ce que les données recueillies dans le cadre de l’utilisation du Site soient traitées légalement et loyalement.</p>
            <p>Nous prenons les dispositions nécessaires pour que vos données à caractère personnel recueillies via le Site ne soient ni perdues, ni détournées, ni consultées ou modifiées indûment, ni divulguées à des tiers non autorisés.</p>
            <p>A cette fin,</p>
            <ul>
              <li>Nous ne collectons que des données utiles à la bonne marche des Services.</li>
              <li>Nous ne traitons aucune donnée dite « sensible » ayant trait à l’origine raciale ou ethnique, les opinions politiques, les convictions religieuses ou philosophiques ou l’appartenance syndicale, ou les données génétiques, biométriques, concernant la santé ou la vie sexuelle des personnes.</li>
              <li>Nous ne revendons pas les données personnelles de nos utilisateurs.</li>
              <li>Nous choisissons avec attention nos prestataires et fournisseurs afin de réduire les risques liés à l’utilisation de vos données personnelles.</li>
            </ul>
            <p>La présente Politique de confidentialité est accessible en permanence sur la page d’accueil du Site, en cliquant sur le lien hypertexte « Politique de confidentialité » en pied de page du Site.</p>
            <p>Nous nous réservons le droit de modifier la présente Politique de confidentialité afin de la mettre à jour suivant les évolutions de la réglementation relative à la protection des données à caractère personnel et de nos procédures internes. Nous vous invitons donc à la consulter régulièrement avant toute utilisation du Site.</p>
            <p>Si des modifications majeures sont apportées, nous vous informerons par email ou par le Site pour vous permettre d'examiner ces modifications avant qu'elles ne prennent effet. Si vous continuez à utiliser nos Services après la publication ou l'envoi d'une information concernant les modifications apportées à la Politique de protection des données personnelles, cela signifie que vous acceptez les mises à jour.</p>

            <h2>Quelles données personnelles collectons-nous, pourquoi et pour quelle durée ?</h2>
            <p>Lors de votre inscription à L’Atelier des auteurs, nous pouvons être amenés à réaliser les traitements suivants sur vos données à caractère personnel :</p>

            <table>
              <thead>
                  <tr>
                      <th  style={{  border: "1px solid black"}}>Finalités du traitement</th>
                      <th  style={{  border: "1px solid black"}}>Données traitées (1)</th>
                      <th  style={{  border: "1px solid black"}}>Base(s) légale(s)</th>
                      <th  style={{  border: "1px solid black"}}>Durée de conservation</th>
                  </tr>
              </thead>
              <tbody>
                  <tr>
                      <td style={{  border: "1px solid black"}}>
                        <p><b>Création d’un compte utilisateur gratuit sur le Site </b></p>
                        <p>1) Création du compte </p>
                        <p>2) Envoi d’offres commerciales et d’actualités adaptées à vos intérêts (conformément à l’article L.34-5 du Code des postes et communications électroniques)</p>
                      </td>
                      <td style={{  border: "1px solid black"}}>
                        <p>1) Nom d’utilisateur, adresse mail et toutes données de personnalisation du compte utilisateur</p>
                        <p>2) Nom d’utilisateur, adresse mail</p>
                        <p>Ces données peuvent faire l’objet d’une collecte directe ou indirecte, auprès de Facebook, lorsqu’il vous est proposé de vous créer un compte utilisateur en associant votre compte Facebook.</p>
                        <p>Dans le cadre de votre demande de connexion au moyen de vos identifiants Facebook, l’adresse mail et le profil que vous avez communiqués à Facebook nous sont transmises conformément aux conditions générales d’utilisation du réseau social.</p>
                      </td>
                      <td style={{  border: "1px solid black"}}>
                        <p>1) Exécution d’un contrat</p>
                        <p>2) Intérêt légitime</p>
                      </td>
                      <td style={{  border: "1px solid black"}}>
                        <p>1) 2) <b>Jusqu’à résiliation de votre compte utilisateur</b> ou à défaut, <b>3 ans à compter de votre dernière activité</b> (celle-ci correspondant à la dernière connexion à votre compte)</p>
                        <p>2) Vous pouvez vous opposer à tout moment à l’envoi d’offres commerciales en cliquant sur le lien de désinscription fourni dans chacune des communications ou en prenant directement contact avec nous (contact@atelierdesauteurs.com).</p>
                      </td>
                  </tr>
                  <tr>
                      <td style={{  border: "1px solid black"}}>
                        <p><b>Souscription à une offre payante sur le Site :</b></p>
                        <p>1) Conservation du contrat à des fins probatoires et d’accès par l’Utilisateur</p>
                        <p>2) Gestion de la facturation des abonnements</p>
                        <p>3) Envoi d’offres commerciales et d’actualités adaptées à vos intérêts (conformément à l’article L.34-5 du Code des postes et communications électroniques)</p>
                      </td>
                      <td style={{  border: "1px solid black"}}>
                        <p>Les données collectées à l’occasion de la création du compte utilisateur définies ci-avant et plus particulièrement :</p>
                        <p><b><u>Pour les traitements 1) 2)</u></b></p>
                        <p>Nom, prénom, adresse email, adresse postale, numéro de téléphone</p>
                        <p><u><b>Pour le traitement 3)</b></u></p>
                        <p>Nom, prénom, adresse mail</p>
                      </td>
                      <td style={{  border: "1px solid black"}}>
                        <p>1) 2) Exécution d’un contrat </p>
                        <p>3) Intérêt légitime</p>
                      </td>
                      <td style={{  border: "1px solid black"}}>
                        <p>1) Le <b>temps de la souscription</b> (3) puis archivage pendant <b>5 ans</b>, ou <b>10 ans</b> lorsque le contrat porte sur une somme égale ou supérieure à 120€ </p>
                        <p>2) <b>10 ans</b></p>
                        <p>3) La durée <b>de la souscription à un abonnement payant</b> et au-delà, <b>jusqu’à 3 ans</b> à compter de la fin de l’abonnement.</p>
                        <p>Vous pouvez vous opposer à tout moment à l’envoi d’offres commerciales en cliquant sur le lien de désinscription fourni dans chacune des communications ou en prenant directement contact avec nous (contact@atelierdesauteurs.com).</p>
                      </td>
                  </tr>
                  <tr>
                      <td style={{  border: "1px solid black"}}>
                        <p><b>Envoi de communications par emails</b></p>
                      </td>
                      <td style={{  border: "1px solid black"}}>
                        <p>Nom, prénom, adresse email</p>
                        <p>Vous pouvez sélectionner depuis votre compte utilisateur les communications que vous souhaitez recevoir de l’Atelier des auteurs (newsletter, conseils d’écriture)</p>
                      </td>
                      <td style={{  border: "1px solid black"}}>
                        <p>Votre consentement</p>
                      </td>
                      <td style={{  border: "1px solid black"}}>
                        <p>Jusqu’à <b>désinscription de la newsletter concernée</b> ou à défaut <b>3 ans</b> à compter de votre dernière activité (celle-ci correspondant à la dernière ouverture d’une newsletter envoyée)</p>
                        <p>La désinscription peut se faire selon l’une des façons suivantes :</p>
                        <p>- en décochant la case dédiée dans les paramètres du compte utilisateur,</p>
                        <p>- en cliquant sur le lien de désinscription fourni dans chacune des communications,</p>
                        <p>- en prenant contact directement avec nous (contact@atelierdesauteurs.com).</p>
                      </td>
                  </tr>
                  <tr>
                      <td style={{  border: "1px solid black"}}>
                        <p><b>Gestion de la relation avec les Utilisateurs</b></p>
                      </td>
                      <td style={{  border: "1px solid black"}}>
                        <p>Nom, prénom, adresse mail, contenu de la demande</p>
                      </td>
                      <td style={{  border: "1px solid black"}}>
                        <p>Votre consentement</p>
                      </td>
                      <td style={{  border: "1px solid black"}}>
                        <p>Le <b>temps du traitement de la demande</b></p>
                      </td>
                  </tr>
                  
              </tbody>
            </table>
            <p>(1) Les informations marquées d’un astérisque sur les formulaires de collecte sont obligatoires à défaut votre demande ne pourra pas être traitée.</p>
            <p>(2) Votre nom d’utilisateur, qui vous identifiera auprès des autres Utilisateurs lors de votre navigation sur le Site, peut être votre véritable identité pour l’utilisation des Services ou un pseudonyme, lorsque vous naviguez gratuitement sur le Site. Vous pouvez changer votre identité publique quand vous le souhaitez à partir des paramètres de votre profil sur le Site.</p>
            <p>Lors de votre souscription à une offre payante sur le Site, vous devez renseigner vos véritables identité et adresse postale afin que nous puissions établir une facturation et gérer notre relation avec vous.</p>
            <p>(3) Afin de mettre régulièrement à jour les bases de données Utilisateurs gérées par l’Atelier des auteurs, il sera envoyé à l’Utilisateur, tous les 5 (cinq) ans, un mail à l’adresse e-mail renseignée lors de la souscription, ou modifiée par la suite, par lequel il sera invité à valider la poursuite de son abonnement.</p>
            <p>A défaut de validation dans un délai de 1 (un) mois calendaire suivant la réception du mail, l’Utilisateur sera réputé inactif et son abonnement sera résilié le jour suivant.</p>
            
            <h2>Suivi de l’activité des Utilisateurs</h2>
            <p>L’Atelier des auteurs utilise des méthodes d’analyses des comportements de ses utilisateurs permettant de suivre leur activité sur le Site et lors de l’utilisation des Services. Vous pourrez retrouver publiquement une partie de ces données dans l’onglet « Activité » de chaque profil du compte utilisateur (nombre d’œuvres publiées et de lectures reçues, nombre de commentaires publiés et reçus etc…).</p>
            <p>Ces données sont également agrégées et anonymisées afin de nous aider à suivre l’activité du Site. Seules les données agrégées et anonymisées peuvent être transmises à d’autres acteurs (clients, fournisseurs, grand public…) au sein de documents traitant de l’activité de l’Atelier des auteurs, par exemple : la croissance du nombre d’utilisateurs par mois, le nombre de commentaires publiés en moyenne chaque jour, etc.</p>

            <h2>Présence et finalités des cookies sur L’Atelier des auteurs</h2>
            <p>Un cookie est un petit fichier texte, identifié par un nom, qui peut être transmis à votre navigateur par un site web ou une application sur lequel vous vous connectez. Votre navigateur web le conservera pendant une certaine durée, et le renverra au serveur web chaque fois que vous vous y reconnecterez. Un cookie peut avoir plusieurs fonctions et il permet notamment de reconnaître un visiteur lorsqu’il revient sur un site web ou une application.</p>
            <p>Les données collectées via les cookies sont conservées pour une durée de 13 mois maximum.</p>
            <p><u>Les cookies déposés et exploités par les entités du groupe Editis :</u></p>
            <p>Il s’agit de cookies utilisés pour faciliter votre navigation et personnaliser votre accès au Site. Ils nous permettent également d’identifier vos centres d’intérêt suivant les pages consultées sur le Site et d’adapter l'offre publicitaire qui vous est adressée sur les sites tiers.</p>
            <p><u>Les cookies exploités par des tiers :</u></p>
            <p>Editis permet à des sociétés tierces de déposer des cookies (telles que, notamment, régies publicitaires, annonceurs, agences de conseil en communication, des sociétés de mesure d'audience et de mesure de performance de campagnes publicitaires, des prestataires de publicité ciblée, partenaires dans le cadre de programmes d’affiliation) afin d'établir votre profil et identifier vos centres d'intérêt, de personnaliser et organiser les contenus et les fonctionnalités du Site, optimiser l’envoi de nos newsletters, déterminer la provenance de leurs visiteurs et calculer la rémunération qui en découle.</p>
            {/* <p>Editis, qui fait partie du groupe Vivendi, permet également le dépôt de cookies tiers exploités par les entités du groupe Vivendi dont la liste exhaustive et régulièrement mise à jour est accessible au lien suivant http://www.vivendi.com/vivendi/vivendi-en-bref-2/. Nous sommes également susceptibles de partager avec ces entités les données collectées via les cookies et traceurs déposés par nous et nos partenaires.</p> */}
            <p><u>Les différents types de cookies</u></p>
            <p>Nous vous invitons à consulter la rubrique « Cookies » accessible en pied de page pour connaître l’ensemble des cookies présents sur le Site et exprimer vos choix.</p>

            <h2>Le partage et le transfert de données avec des tiers.</h2>
            <p>L’Atelier des auteurs ne revend pas les données personnelles de ses Utilisateurs à des entreprises tierces.</p>
            <p>Les entités suivantes sont toutefois susceptibles d’avoir accès à certaines de vos données :</p>
            <p><u>Destinataires appartenant au groupe Editis</u></p>
            <p>A l’occasion de votre inscription à la Newsletter, les sociétés du groupe Editis suivantes peuvent avoir accès à vos données :</p>
            <p>- Sogedif</p>
            <p><i>Sogedif est une société de services du groupe Editis qui assure notamment la gestion de la base de données utilisateurs des maisons d’édition du Groupe.</i></p>
            {/* <p><u>Destinataires appartenant au Groupe Vivendi</u></p>
            <p>Editis appartenant au Groupe Vivendi, vos données peuvent être communiquées aux entités du Groupe Vivendi, à savoir Prisma Media, Groupe Canal +, Havas, Gameloft, Vivendi Village et Dailymotion, dans le cas où vous auriez expressément accepté de recevoir des offres et actualités de leur part.</p>
            <p>Nous vous invitons à consulter la politique de confidentialité de ces entités dont la liste régulièrement mise à jour est accessible à l’adresse suivante http://www.vivendi.com/vivendi/vivendi-en-bref-2/.</p>
            <p>Vos données sont traitées dans le cadre d’un accord conclu avec l’ensemble des entités du Groupe Vivendi afin de garantir la sécurité et la protection de vos données conformément à la règlementation nationale et européenne.</p> */}
            <p><u>Partenaires et sous-traitants de l’Atelier des auteurs</u></p>
            <p>Afin de pouvoir réaliser les traitements visés à l’article 1 ci-avant, nous faisons appel à des sous-traitants qui assurent des prestations pour notre compte :</p>
            <p>- Stripe : prestataire de traitement des paiements en ligne ;</p>
            <p>- Amazon Web Service : hébergement de l’Atelier des Auteurs ;</p>
            <p>- Mailjet : prestataire d’envoi d’emails et de newsletters ;</p>
            <p>- Google Analytics : metrics et suivi des comportements d’utilisateurs ;</p>
            <p>- Didomi : cookie manager.</p>
            <p><u>Réseaux sociaux :</u></p>
            <p><u>Autorités judiciaires</u></p>
            <p>Conformément à l’article 6-II de la loi du 21 juin 2004 pour la Confiance dans l’économie numérique, les données de nature à permettre votre identification peuvent être communiquées à l’autorité judiciaire lorsqu’elle le requiert.</p>
            <p><u>Transfert hors UE</u></p>
            <p>Nous limitons autant que possible le transfert de vos données personnelles à des prestataires et/ou sous-traitants situés en dehors de l’Union Européenne. En outre, lorsque nous procédons à un transfert à un prestataire et/ou sous-traitant situé en dehors de l’Union Européenne, nous nous assurons que le transfert s’effectue soit vers un pays offrant un niveau de protection adéquat au sens du Règlement (UE) 2016/679 relatif à la protection des données à caractère personnel, soit que ce transfert soit encadré par des outils juridiques lui garantissant un niveau de protection conforme aux exigences européennes (par exemple, par la conclusion de Clauses Contractuelles Types de la Commission européenne).</p>
      
            <h2>Comment sont protégées vos données personnelles ?</h2>
            <p>L’Atelier des auteurs applique les mesures de sécurité généralement reconnues afin que les données à caractère personnel recueillies ne soient, ni perdues, ni détournées, ni consultées, ni modifiées ni divulguées sans le consentement de l’Utilisateur ou à la demande d’une autorité judicaire (ou toute autre obligation légale de ce type).</p>
            <p>La sécurité des données personnelles dépend également des Utilisateurs. Les Utilisateurs qui sont membres de L’Atelier des auteurs s'engagent à conserver la confidentialité de leur identifiant et de leur mot de passe. Les Utilisateurs s'engagent également à ne pas partager leur compte et à déclarer à L’Atelier des auteurs toute utilisation non autorisée dudit compte dès lors qu'ils en ont connaissance.</p>
            <p>Certaines données personnelles ne peuvent être révélées même sur demande d’une autorité judiciaire. C’est le cas des mots de passe enregistrés sur L’Atelier des auteurs dont le chiffrement ne permet pas leur connaissance, même par l’administration du Site. C’est pourquoi nous ne pouvons pas révéler le mot de passe créé par un Utilisateur du Site, même à lui-même.</p>

            <h2>Quels sont vos droits ?</h2>
            <p>Conformément à la loi Informatique et Libertés du 6 janvier 1978 modifiée et au Règlement (UE) 2016/679 relatif à la protection des données à caractère personnel, vous disposez des droits suivants sur vos données, dans les conditions et limites définies par ces textes : droit d’accès, droit de rectification, droit à l’effacement (droit à l’oubli), droit d’opposition, droit à la limitation du traitement, droit à la portabilité.</p>
            <p>Vous pouvez également définir des directives relatives à la conservation, à l'effacement et à la communication de vos données à caractère personnel après votre décès.</p>
            <p>Pour exercer vos droits, merci d’adresser votre courrier RAR à l’adresse suivante DPO EDITIS, 92 avenue de France, 75013, Paris ou à l’adresse électronique suivante DPO-Editis@Editis.com.</p>
            <p>Pour toute résiliation de votre compte sur le Site, vous pouvez envoyer votre demande à l’adresse contact@atelierdesauteurs.com. En résiliant votre compte, vous perdrez le bénéfice des Services.</p>
            <p>Nous nous efforcerons de traiter vos demandes d’exercice de vos droits dans un délai maximum d’un mois suivant la réception de celles-ci. En cas de demande complexe ou multiple nécessitant un délai plus long, nous vous informerons de cette prolongation et de ses motifs dans un délai d'un mois à compter de la réception de votre demande.</p>
            <p>Afin d’accéder à votre demande, nous pouvons vous demander l’envoi d’un email à partir d’une adresse identifiée comme étant la vôtre et/ou une photocopie d'un justificatif d'identité.</p>
            <p>En cas de manquement aux dispositions ci-dessus, vous avez le droit d’introduire une réclamation auprès de la CNIL.</p>

            <h2>Coordonnées du Délégué à la Protection des Données</h2>
            <p>DPO EDITIS</p>
            <p>92 avenue de France, 75013, Paris</p>
            <p>DPO-Editis@Editis.com</p>

          </div>
        </div>
      </div>
    );
  }
}
